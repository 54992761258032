<template>
    <div style="width: fit-content; background-color: white;" id="inputDiv">
        <div>
            <b-sidebar id="sidebar-help" title="Help" shadow right>
                <div style="padding: 1rem;">
                    <h2 class="pb-5" style="font-weight: 600;">Stock Charts</h2>
                    <h3 class="pb-3">Indicators:<br>
                        <span style="font-size: 12px;">(the following indicators are available) </span>
                    </h3>
                    <h4><b>ATR: </b>Average True Range</h4>
                    <h4><b>BB: </b>Bollinger Bands</h4>
                    <h4><b>MACD: </b>Moving Average Convergence / Divergence</h4>
                    <h4><b>CCI: </b>Commodity Channel Index</h4>
                    <h4><b>RSI: </b>Relative Strange Index</h4>
                    <h4><b>SMA: </b>Simple Moving Average</h4>
                    <h4><b>VOL: </b>Volume and Open Interest</h4>
                    <h4><b>VP: </b>Volume Profile</h4><h4>Seasonals are made using the "average percentage method".</h4>
                    <h3 class="pt-5">Tools:</h3>
                    <h4><b>Show/Hide Label: </b>show or hide information labels on the chart.</h4>
                    <h4><b>P/L (Profit &amp; Loss calculator): </b>to measure the price difference in USD between two points selected with the mouse.</h4>
                    <h4><b>Download and Annotations: </b>accessible via the icon with a symbol at the top left of the graph</h4>
                    <hr>
                    <h4>Need support? Visit the Support page or <a href="/support">click here</a></h4>
                </div>
            </b-sidebar>
        </div>

        <table v-if="namespace !== 'undefined'" style="text-align: left; margin-bottom: 0px; margin-top: 2px; vertical-align: bottom;
               width: 100%;"> 
            <tr style="text-align: left; margin-bottom: 0px; vertical-align: bottom;">
                <td>
            <b-container fluid style="width: auto; margin-top: 0px; background-color: white; margin-left: -30px;">
                <b-row align-h="start" align-v="end" style="background-color: white; width: auto; padding: 1px;" class="row no-gutters">
                    <b-col md="auto" style="margin-right: 1px; margin: 0px -4px -2px 3px; padding: 1px; width: auto; background-color: white">  
                        <stock-box v-bind:namespace="namespace"></stock-box>                      
                    </b-col>
                  <b-col cols="" style="margin-right: 1px">  
                        <b-button-group style="border: 1px solid gray; background-color: #E8E8E8; margin: 2px 1px 1px 1px; border-radius: 3px;">
                            <stock-graph-type-toggle v-bind:namespace="namespace" v-if="legs === 1"
                            v-bind:click-handler="'am4charts.changeStockGraphType'" 
                            style="margin: 1px 0 0 1px"></stock-graph-type-toggle>
                            <truncate-selector v-bind:namespace="namespace" style="padding-left: 5px; margin: 1px"></truncate-selector> 
                            <indicators v-bind:namespace="namespace" style="margin: 1px"></indicators>
                        </b-button-group>

                        <b-button-group style="border: 1px solid gray; padding: 2px; white-space: nowrap; background-color: lightgray;
                                        margin: 2px 1px 1px 1px; border-radius: 3px;"> 
                            <b-button v-on:click="$root.$emit('change-period', '3-MM')" id="3-MM" size="sm" style="margin-left: 0px; background-color: darkgray; color: white;">3 mo</b-button>
                            <b-button v-on:click="$root.$emit('change-period', '6-MM')" id="6-MM" size="sm" style="margin-left: 1px; background-color: darkgray; color: white;">6 mo</b-button>
                            <b-button v-on:click="$root.$emit('change-period', '1-YYYY')" id="YYYY" size="sm" style="margin-left: 1px; background-color: darkgray; color: white;">1 yr</b-button>
                            <b-button v-on:click="$root.$emit('change-period', '1-MAX')" id="MAX" size="sm" style="margin-left: 1px; background-color: darkgray; color: white;">MAX</b-button>
                        </b-button-group> 

                        <b-button-group style="border: 1px solid gray; padding: 1px; margin: 2px 1px 1px 1px; white-space: nowrap;
                                        background-color: #E8E8E8; border-radius: 3px;"> 
                            <toggle-single-throw v-bind:namespace="namespace" v-if="normalizedStockGraphType === 'line'" 
                                                 v-bind:property="'showBullets'" v-bind:label="'bullets'" style="margin: 0px;"></toggle-single-throw>
                            <toggle-single-throw v-if="['admin 1', 'superadmin1'].includes($store.state.user.role)" v-bind:namespace="namespace"
                                                 v-bind:property="'showPlaybackControl'" v-bind:label="'playback'" style="margin: 0px"></toggle-single-throw>
                            <add-to-portfolio-button v-if="['admin 1', 'superadmin1'].includes($store.state.user.role)" v-bind:namespace="namespace" style="margin: 1px 0 0 0"></add-to-portfolio-button>                            
                            <b-button v-b-toggle.sidebar-help variant="warning" size="sm" style="border: 0px solid darkgray; border-radius: 3px;; margin: 1px;">Help</b-button>
                            <b-col md="auto" style="margin-right: 0px; padding: 1px">  
                                <trade-specs v-bind:namespace="namespace"></trade-specs>
                            </b-col>
                        </b-button-group>

                    </b-col>
                </b-row>
            </b-container>
            </td>
            </tr>
            <tr>
                <td>

                </td>
            </tr>
        </table>

    </div>
</template>

<script>

    import stockBox from '@/components/stock-box';
    import toggleSingleThrow from '@/components/toggle-single-throw';
    import stockGraphTypeToggle from '@/components/stock-graph-type-toggle';
    import truncateSelector from '@/components/truncate';
    import tradeSpecs from '@/components/trade-specs';
    import indicators from '@/components/indicators';
    import addToPortfolioButton from '@/components/add-to-portfolio-button';

    export default {
        components: {
            stockBox,
            toggleSingleThrow,
            stockGraphTypeToggle,
            truncateSelector,
            tradeSpecs,
            addToPortfolioButton,
            indicators,
        },
        mounted() {
            // console.log("stock-chart-input.vue mounted() starting. this.namespace=", this.namespace);

            let observer = new ResizeObserver(entries => {
                // console.log("ResizeObserver() starting. this.namespace=", this.namespace, " activeModuleName=", this.$store.getters['activeModuleName']);
                if (this.$store.getters['activeModuleName'] === this.namespace) {
                    //  console.log("checking input height.");
                    for (let entry of entries) {
                        // console.log("entry=", entry);
                        // Now do something with the resized element
                        //  console.log("entry.contentRect.height=", entry.contentRect.height);
                        this.$store.commit(this.namespace + '/browserSideOnly/setInputDivHeight', entry.contentRect.height);
                    }
                }
            });
            observer.observe(document.querySelector('#' + this.namespace + 'InputDiv'));
        },
        props: ['namespace'],
        computed: {
            normalizedStockGraphType() {
                return this.$store.getters[this.namespace + "/normalizedStockGraphType"];
            },
            legs(){
                return this.$store.state[this.namespace].legs;
            }
        }
    };

</script>

<style>
    .px-0 {
        padding: 0;
    }
</style>
